//importing components
import ProductResults from '../../components/Products';
import {useEffect} from "react";
//search page
const Search: React.FC = () => {
  useEffect(() => {
    document.title = 'Shop Colorful Expression';
  }, []);
  return (
    <div>
      <ProductResults />
    </div>
  );
};

export default Search;
