//importing hooks
import { useCartActions } from '../../../hooks';
//importing types
import { ProductData } from '../../../interfaces';

import {
  CardMedia,
  Card,
  Typography,
  Box,
  CardContent,
  Stack,
  Button,
} from "@mui/material"

interface CheckoutItemProps {
  product: ProductData;
}

const CheckoutItem: React.FC<CheckoutItemProps> = ({ product }) => {
  const { removeCartItem } = useCartActions();
  const { thumbnail, price, documentId, name } = product;

  const handleRemoveCartItem = (documentId: string) =>
    removeCartItem(documentId);

  // const handleReduceCartItem = (product: ProductData) =>
  //   reduceCartItem(product);

  // const handleAddProduct = (product: ProductData) => addCartItem(product);

  return (
    <Card raised sx={{ display: 'flex' }}>
        <CardMedia 
          component="img"
          sx={{ width: 151, padding: "1em 1em 1em 1em", objectFit: "contain"}}
          image={`https://ik.imagekit.io/cdycj9lbl/tr:h-200,w-150${thumbnail}`}
          alt={name}
        />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Stack direction="column" justifyContent={"space-between"} spacing={2} sx={{height: "100%"}}>
            <Typography component="div" variant="h5">
              {name}
            </Typography>
            <Typography variant="h6" >
              1 x ${price}
            </Typography>
            <Box><Button size='small' onClick={() => handleRemoveCartItem(documentId!)} variant="contained" color="secondary" >Remove</Button></Box>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

export default CheckoutItem;
