//types
export enum ActionType {
  //START
  SAVE_ORDER_HISTORY_START = 'SAVE_ORDER_HISTORY_START',
  GET_ORDER_HISTORY_START = 'GET_ORDER_HISTORY_START',
  GET_ORDER_DETAILS_START = 'GET_ORDER_DETAILS_START',
  //OTHER
  SET_ORDER_HISTORY = 'SET_ORDER_HISTORY',
  SET_ORDER_DETAILS = 'SET_ORDER_DETAILS',
}
