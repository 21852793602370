//importing types
import { ProductData } from '../../../interfaces';
import { useState } from 'react';
//importing components
import { downloadBook } from '../../../firebase/utils';
import {
  Button,
  Typography,
  CardActions,
  CardContent,
  CardMedia,
  CardActionArea,
  Card,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
} from "@mui/material"

//props interface
interface ProductProps {
  product: ProductData;
}
//product component
const Product: React.FC<ProductProps> = ({ product }) => {
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorTitle, setErrorTitle] = useState('Downloading Book');
  const [errorMessage, setErrorMessage] = useState('');
  //destructuring
  const { name, thumbnail, documentId, filename } = product;
  //type guards
  if (!documentId) return null;
  //on click handler
  const handleDownload = (product: ProductData) => {
    setOpen(true);
    setShowLoader(true);
    setErrorTitle('Downloading Book');
    if(filename){
      downloadBook('/products/'+product.documentId, product.name, setOpen).then(()=>{
      }).catch(()=> {
        setShowLoader(false);
        setErrorMessage('Something went wrong. Please try again or contact support@colorfulexpression.com');
      })
    } else {
      setShowLoader(false);
      setErrorMessage('Something went wrong. Please try again or contact support@colorfulexpression.com');
    }
  };
  return (
    <Card raised>
    <CardActionArea >
      <CardMedia 
        component="img"
        sx={{ padding: "1em 1em 0 1em", objectFit: "contain"}}
        image={`https://ik.imagekit.io/cdycj9lbl/tr:h-400,w-300${thumbnail}`}
        alt={name}
      />
      <CardContent sx={{padding: '10px'}}>
        <Typography variant="h5" component="div" align="center">
          {name}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions sx={{padding: '15px'}}>
      <Stack direction="row" justifyContent="space-around" sx={{width: '100%'}}>
        <Button variant="contained" size="small" color="secondary" onClick={() => handleDownload(product)}>
          Download
        </Button>
      </Stack>
    </CardActions>
    <Dialog open={open} onClose={()=>{setOpen(false)}}  fullWidth={true}>
        <DialogTitle sx={{fontWeight: "700"}}>{errorTitle}</DialogTitle>
        <DialogContent>
          {showLoader && <Box sx={{ display: 'flex' }} justifyContent={"center"}>
            <CircularProgress color="secondary"/>
          </Box>}
          {errorMessage && <Typography id="modal-modal-title" variant="h6" component="h2">
              {errorMessage}
            </Typography>}
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Product;
