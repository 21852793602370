//types
export enum ActionType {
  //START
  EMAIL_SIGN_IN_START = 'EMAIL_SIGN_IN_START',
  EMAIL_SIGN_UP_START = 'EMAIL_SIGN_UP_START',
  EMAIL_SIGN_OUT_START = 'EMAIL_SIGN_OUT_START',
  GOOGLE_SIGN_IN_START = 'GOOGLE_SIGN_IN_START',
  PASSWORD_RECOVERY_START = 'PASSWORD_RECOVERY_START',
  //SUCCESS
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  GOOGLE_SIGN_IN_SUCCESS = 'GOOGLE_SIGN_IN_SUCCESS',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS',
  //ERROR
  USER_ERROR = 'USER_ERROR',
  //OTHER
  CHECK_USER_SESSION = 'CHECK_USER_SESSION',
  RESET_USER_STATE = 'RESET_USER_STATE',
}
