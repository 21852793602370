//firebase utils
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from './config';
//importing interfaces
import { HandleUser } from '../interfaces';
import { getStorage, ref, connectStorageEmulator, getBlob } from "firebase/storage";
//exporing utilities
export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.firestore();
export const functions = app.functions();
export const analytics = getAnalytics(app);
const storage = getStorage();

export const logWebEvent = (event: any, blob: any) => {
  logEvent(analytics, event, blob);
}
export const downloadBook = async (filename: string, title: string, setOpen: any) => {
    const starsRef = ref(storage, filename);
    getBlob(starsRef).then((blob: Blob) => {
      console.log(`Downloaded`)
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${title}.pdf`;
      link.click();
      setOpen(false);
  })
  .catch((error) => {
    console.log('error downloading file:', error)
  })
}

if ( process.env.REACT_APP_PUBLIC_ENV==="dev" || process.env.REACT_APP_PUBLIC_ENV==="test" || process.env.REACT_APP_PUBLIC_ENV==="local") {
  auth.useEmulator("http://korriban:9099");
  db.useEmulator('korriban', 8044);
  functions.useEmulator("korriban", 5001);
  connectStorageEmulator(storage, "korriban", 9199);
}

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: 'select_account' });
//saving user profile to firestore
export const handleUserProfile = async ({ userAuth, moreData }: HandleUser) => {
  if (!userAuth) {
    return null;
  }
  //getting currentUser properties
  const { uid, displayName, email } = userAuth;
  const timeStamp = new Date();
  const userRoles = ['user'];
  //creating a doc with user id
  const userRef = db.doc(`users/${uid}`);
  //getting user's data
  const userData = await userRef.get();
  //saving user's data
  if (!userData.exists) {
    try {
      await userRef.set({
        email,
        displayName,
        createdDate: timeStamp,
        userRoles,
        ...moreData,
      });
    } catch (err: any) {
      console.log(err.message);
    }
  }
  //returning currentUser
  return userRef;
};
//current user helper function
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};
