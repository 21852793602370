//importing hooks & random id's
import { useEffect, useState } from 'react';
import { useProductsActions, useTypedSelector, useCartActions } from '../../hooks';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { functions, logWebEvent } from '../../firebase/utils';

//importing components
import Product from './Product';
import LoadMore from '../LoadMore';
import {
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material"
//products component
const BookShelf: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const { search } = useLocation()
  const values = queryString.parse(search)

  const [redeemCode, setRedeemCode] = useState(values.code || '');

  const { fetchBookshelfStart } = useProductsActions();
  const { clearCart } = useCartActions();

  const { data, queryDoc, isLastPage } = useTypedSelector(
    state => state.productsData.bookshelf
  );
  const { currentUser } = useTypedSelector(state => state.user);
  
  useEffect(() => {
    if (currentUser) fetchBookshelfStart(currentUser.bookshelf);
  }, [fetchBookshelfStart, currentUser]);

  useEffect(() => {
    if (values && values.purchase){
      logWebEvent("purchase", {"currency": "USD", "transaction_id": values.orderid, "value": values.value});
      clearCart();
    }
  }, [clearCart, values]);

  //on load more handler
  const onLoadMoreHandler = () => {
    if (queryDoc && currentUser) fetchBookshelfStart(currentUser.bookshelf, queryDoc, data);
  };
  //type guards
  if (!Array.isArray(data)) return null;
  const loadMoreConfig = {
    onLoadMore: onLoadMoreHandler,
  };

  const handleRedeem = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowLoader(true);
    setOpen(true);
    setErrorMessage('');
    setErrorTitle('Validating Code...')
    let result;
    try {
      logWebEvent("redeem_code_entered", {"code": redeemCode})
      result = await functions.httpsCallable("redeemCode")({code: redeemCode});
    } catch (error: any){
      logWebEvent("redeem_code_failed", {"code": redeemCode, "message": error.message})
      setShowLoader(false);
      setErrorTitle('Failed To Redeem Code:');
      setErrorMessage(`Error:${error.message}. Try again, or contact support@colorfulexpression.com`);
      return
    }
    if(!result){
      logWebEvent("redeem_code_failed", {"code": redeemCode, "message": "empty result"})
      setShowLoader(true);
      setErrorTitle('Failed To Redeem Code');
      setErrorMessage('Unable to redeem code');
      return 
    }
    setShowLoader(false);
    setRedeemCode('');
    logWebEvent("redeem_code_success", {"code": redeemCode, "product": result.data})
    const shelf = [result.data].concat(currentUser?.bookshelf || []);
    fetchBookshelfStart(shelf);
    setErrorTitle('Success! Refresh the page to see your new title.')
    setErrorMessage('');
  }


  return (
    <>
      <Stack spacing={2} mt="1rem">
          <Typography variant="h3">
          Your Books
          </Typography>
          <form onSubmit={handleRedeem}>
          <Stack direction={"row"} spacing={1} sx={{width: "100%"}}>
            <Button type="submit" color="secondary" variant='contained' disabled={!currentUser}>Redeem Code</Button>
            <TextField
              onChange={e => setRedeemCode(e.target.value)}
              type="text"
              name="redeemCode"
              placeholder="Digital Copy Code"
              value={redeemCode}
            />
            </Stack>
            <Typography variant="subtitle2">Physical books come with a code to redeem a FREE digital copy you can print as any times as you like.</Typography>
          </form>
          {currentUser && data.length<1 && <div className="products">
            <p>No results yet! Your titles will show here when purchased or redeemed.</p>
          </div>}
          <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }} justifyContent="center" >
            {data.map(productData => {
              return <Grid item key={productData.documentId}><Product product={productData} key={uuidv4()} /></Grid>;
            })}
            {!currentUser && <Grid item xs={12} sm={5}><Button onClick={()=>{history.push('/login')}}  color="secondary" variant="contained" sx={{fontWeight: 700, width: '100%'}}>{'Login To Get Started'}</Button></Grid>}
          </Grid>
          {data.length>0 && !isLastPage && <Box sx={{width: "100%"}} textAlign="center"><LoadMore {...loadMoreConfig} /></Box>}
      </Stack>
      <Dialog open={open} onClose={()=>{setOpen(false)}}  fullWidth={true}>
        <DialogTitle sx={{fontWeight: "700"}}>{errorTitle}</DialogTitle>
        <DialogContent>
          {showLoader && <Box sx={{ display: 'flex' }} justifyContent={"center"}>
            <CircularProgress color="secondary"/>
          </Box>}
          {errorMessage && <Typography id="modal-modal-title" variant="h6" component="h2">
              {errorMessage}
            </Typography>}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookShelf;
