//importing components
import PasswordRecovery from '../../components/PasswordRecovery';
import {useEffect} from "react";
//recovery component
const Recovery: React.FC = () => {
  useEffect(() => {
    document.title = 'Password Recovery';
  }, []);
  return <PasswordRecovery />;
};

export default Recovery;
