//importing component
import Checkout from '../../components/Checkout';
import {useEffect} from "react";
//cart
const Cart = () => {
  useEffect(() => {
    document.title = 'Cart';
  }, []);
  return (
    <div>
       <Checkout />
    </div>
  );
};

export default Cart;
