//importing hooks
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useOrdersActions, useTypedSelector } from '../../hooks';
//importing components
import OrderDetails from '../../components/OrderDetails';
import { Typography, Stack } from '@mui/material';
//order
const Order = () => {
  //redux actions, router params
  const { orderId } = useParams<{ orderId: string }>();
  const { currentUser } = useTypedSelector(state => state.user);
  const { getOrderDetailsStart } = useOrdersActions();
  const { orderDetails } = useTypedSelector(state => state.orders);
  //fetching order details
  useEffect(() => {
    getOrderDetailsStart(`${currentUser?.id}:::${orderId}`);
  }, [getOrderDetailsStart, orderId, currentUser]);
  //destructuring
  if (!orderDetails) return null;
  const { orderTotal } = orderDetails;
  return (
    <Stack spacing={2} sx={{ml:3, mr: 3, mt: 3}}>
      <Typography variant="h5">Order ID: #{orderId}</Typography>
      <OrderDetails order={orderDetails} />
      <Typography variant="h6">Total: ${orderTotal}</Typography>
    </Stack>
  );
};

export default Order;
