//importing firebase utils
import { db } from '../../firebase/utils';
import firebase from 'firebase/compat/app';
//importing types
import { FetchProductsParams, FetchBookshelfParams, ProductData } from '../../interfaces';

//adding products helper functions
export const handleAddProduct = (product: ProductData) => {
  return new Promise((resolve, reject) => {
    db.collection('products')
      .doc()
      .set(product)
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
};
//fetching products helper
export const handleFetchProducts = ({
  filterType,
  startAfterDoc,
  persistProducts,
}: FetchProductsParams) => {
  return new Promise((resolve, reject) => {
    //page size
    const pageSize = 20;
    //collection reference
    let collectionRef = db
      .collection('products')
      .orderBy('name')
      .limit(pageSize);
    //checking if filtertype is valid
    if (filterType && filterType.length > 0)
      collectionRef = collectionRef.where('category', '==', filterType);
    //checking if there is a last doc
    if (startAfterDoc) collectionRef = collectionRef.startAfter(startAfterDoc);
    //getting products
    collectionRef
      .get()
      .then(productsRef => {
        if (persistProducts) {
          const totalCount = productsRef.size;
          const data = [
            ...persistProducts,
            ...productsRef.docs.map(doc => {
              return {
                ...doc.data(),
                documentId: doc.id,
              };
            }),
          ];
          resolve({
            data,
            queryDoc: productsRef.docs[totalCount - 1],
            isLastPage: totalCount < pageSize,
          });
        }
      })
      .catch(err => reject(err));
  });
};

//fetching products helper
export const handleFetchBookshelf = ({
  bookshelf,
  startAfterDoc,
  persistProducts,
}: FetchBookshelfParams) => {
  let ids: string[] = [];
  if(startAfterDoc){
    const startIndex = bookshelf.indexOf(startAfterDoc.id);
    if (startIndex !== -1 && startIndex < bookshelf.length - 1) {
      ids = bookshelf.slice(startIndex + 1, startIndex + 11);
    }
  } else {
    ids = bookshelf.slice(0, 10);
  }
  if(ids.length===0){
    return new Promise((resolve, reject) => {
      resolve({
        data: [],
        queryDoc: null,
        isLastPage: true,
      });
    });
  }
  return new Promise((resolve, reject) => {
    //page size
    const pageSize = 20;
    //collection reference
    let collectionRef = db
      .collection('products')
      .limit(pageSize).where(firebase.firestore.FieldPath.documentId(), 'in', ids)
    //getting products
    collectionRef
      .get()
      .then(productsRef => {
        if (persistProducts) {
          const totalCount = productsRef.size;
          const data = [
            ...persistProducts,
            ...productsRef.docs.map(doc => {
              return {
                ...doc.data(),
                documentId: doc.id,
              };
            }),
          ];
          resolve({
            data,
            queryDoc: productsRef.docs[totalCount - 1],
            isLastPage: totalCount < pageSize,
          });
        }
      })
      .catch(err => reject(err));
  });
};

//fetching product helper
export const handleFetchProduct = (productID: string) => {
  return new Promise((resolve, reject) => {
    db.collection('products')
      .doc(productID)
      .get()
      .then(productRef => {
        if (productRef.exists) {
          resolve(productRef.data());
        }
      })
      .catch(err => reject(err));
  });
};
//deleting product helper
export const handleDeleteProduct = (productID: string) => {
  return new Promise((resolve, reject) => {
    db.collection('products')
      .doc(productID)
      .delete()
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
};
