//importing components
import ProductResults from '../../components/Products';
import {useEffect} from "react";
//homepage
const Homepage: React.FC = () => {
  useEffect(() => {
    document.title = 'Shop Colorful Expression';
  }, []);
  return (
    <section>
    <ProductResults />
    </section>
  );
};

export default Homepage;
