//importing hooks & random id's
import { useEffect } from 'react';
import { useProductsActions, useTypedSelector } from '../../hooks';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
//importing components
import Product from './Product';
import LoadMore from '../LoadMore';
import {Categories} from '../../const';
import {
  Grid,
  Stack,
  Typography,
  Box,
  MenuItem,
} from "@mui/material"
import Select, { SelectChangeEvent } from '@mui/material/Select';

//products component
const ProductResults: React.FC = () => {
  //redux state, router history & actions
  const history = useHistory();
  const { filterType } = useParams<{ filterType: string }>();
  const { fetchProductsStart } = useProductsActions();
  const { data, queryDoc, isLastPage } = useTypedSelector(
    state => state.productsData.products
  );
  //fetching products
  useEffect(() => {
    fetchProductsStart(filterType);
  }, [fetchProductsStart, filterType]);
  //on change handler
  const onChangeHandler = (event: SelectChangeEvent) => {
    const target = event.target.value;
    history.push(`/search/${target}`);
  }

  //on load more handler
  const onLoadMoreHandler = () => {
    if (queryDoc) fetchProductsStart(filterType, queryDoc, data);
  };
  //type guards
  if (!Array.isArray(data)) return null;
  let categoryOptions =[{
    name: 'Show all',
    value: '',
  }];
  const menuItems = [<MenuItem key='all' value="">Show all</MenuItem>];
  for(const category in Categories){
    menuItems.push(<MenuItem key={category} value={category}>{Categories[category]}</MenuItem>)
    categoryOptions.push({value: category, name: Categories[category]})
  }
  const loadMoreConfig = {
    onLoadMore: onLoadMoreHandler,
  };
  return (
    <div className="products" key={uuidv4()}>
      <Stack spacing={2} mt="1rem">
        <Typography variant="h3">
          Browse Titles
        </Typography>
        <Select
        variant="standard"
          id="category-select"
          value={filterType || ''}
          onChange={onChangeHandler}
          displayEmpty
        >
          {menuItems}
        </Select>
        {data.length<1 && <div className="products">
          <p>No search results found.</p>
        </div>}
        <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }} justifyContent="center" >
          {data.map(productData => {
            return <Grid item key={productData.documentId}><Product product={productData} key={uuidv4()} /></Grid>;
          })}
        </Grid>
        {!isLastPage && <Box sx={{width: "100%"}} textAlign="center"><LoadMore {...loadMoreConfig} /></Box>}
      </Stack>
    </div>
  );
};

export default ProductResults;
