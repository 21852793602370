//importing components
import ProductCard from '../../components/ProductCard';
import {useEffect} from "react";
//product details
const ProductDetails = () => {
  useEffect(() => {
    document.title = 'Product Details';
  }, []);
  return (
    <div className="product__details__page">
      <ProductCard />
    </div>
  );
};

export default ProductDetails;
