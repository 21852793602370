//importing hooks
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  useProductsActions,
  useTypedSelector,
  useCartActions,
} from '../../hooks/index';
//importing types
import { ProductData } from '../../interfaces';
//importing components
import {
  Box,
  Button,
  Typography,
  CardActions,
  CardContent,
  CardMedia,
  CardActionArea,
  Card,
  Stack,
  Divider,
} from "@mui/material"

//product card
const ProductCard = () => {
  //redux state, actions & router utils
  const history = useHistory();
  const { productID } = useParams<{ productID: string }>();
  const { fetchProductStart, setProduct } = useProductsActions();
  const { addCartItem } = useCartActions();
  const { product } = useTypedSelector(state => state.productsData);

  useEffect(() => {
    document.title = `Product Details | ${product?.name}`;
  }, [product]);

  //fetching product
  useEffect(() => {
    fetchProductStart(productID);
    //cleanup
    return () => {
      setProduct(null);
    };
  }, [fetchProductStart, productID, setProduct]);
  //type guards
  if (!product) return null;
  //destructuring
  const { name, price, thumbnail, description, asin } = product;
  //on click handler
  const handleAddToCart = (product: ProductData) => {
    addCartItem(product);
    history.push('/cart');
  };
  return (
    <Card raised sx={{mt: 3}}>
      <CardActionArea >
        <CardMedia 
          component="img"
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain", maxHeight: '500px'}}
          image={`https://ik.imagekit.io/cdycj9lbl/tr:h-800,w-600${thumbnail}`}
          alt={name}
        />
        <CardContent sx={{padding: '10px'}}>
          <Typography variant="h4">
            {name}
          </Typography>
          <Typography>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{padding: '15px'}}>
        <Stack justifyContent="space-around" sx={{width: '100%'}}>
          <Stack spacing={2} sx={{width: '100%'}}>
          <Divider />
            <Typography variant="h4">
              Purchase Options
            </Typography>
            <Divider />
            <Box>
              <Typography variant="h6" sx={{fontWeight: 700}}>
                Instant Digital Delivery - ${price}
              </Typography>
              <Typography>
                Download now and print this title as many times as you like!
              </Typography>
              <Button  onClick={() => handleAddToCart(product)} color="secondary" variant="contained" sx={{fontWeight: 700, width: '100%'}}>
                Buy Digital
              </Button>
            </Box>
            <Divider />
            {asin && <Box>
              <Typography variant="h6" sx={{fontWeight: 700}}>
                Physical Copy From Amazon
              </Typography>
              <Typography>
                Order a physical copy of the book from Amazon. Comes with a digital download code inside!
              </Typography>
              <Button href={`https://www.amazon.com/gp/product/${asin}/?ref=as_li_tl?ie=UTF8&tag=colorful-expression-20&linkID=product`}  color="secondary" variant="contained" sx={{fontWeight: 700, width: '100%', backgroundColor: '#ff9900'}}>
                Shop Amazon
              </Button>
            </Box>}
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
