import {
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import {useEffect} from "react";

//Privacy page
const Privacy: React.FC = () => {
  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);
  return (
    <Paper sx={{mt:3}}>
      <Grid container sx={{padding: 3}} justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <div>
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
            Privacy Policy
            </Typography>
            <Typography color="inherit" paragraph>
            Effective Date: 01/01/2023
            </Typography>

            <Typography color="inherit" paragraph>
              At Colorful Expression, we value and respect your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website or engage with our services. By accessing or using our website, you agree to the terms and practices described in this policy.
            </Typography>

            <Typography color="inherit" paragraph  mt={3}>
              1. Information We Collect:
            </Typography>

            <Typography color="inherit" paragraph>
            1.1 Personal Information: We may collect personal information that you voluntarily provide to us, such as your name, email address, shipping address, and payment details when you make a purchase or interact with our website.
            </Typography>

            <Typography color="inherit" paragraph>
            1.2 Log Data: We collect information that your browser sends whenever you visit our website. This may include your IP address, browser type, browser version, the pages you visit, the time and date of your visit, and other statistics.
            </Typography>

            <Typography color="inherit" paragraph>
            1.3 Cookies: We use cookies to enhance your browsing experience and provide personalized content. Cookies are small files stored on your device that allow us to recognize your preferences and improve our services. You have the option to disable cookies in your browser settings; however, please note that certain features of our website may not function properly as a result.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            2. How We Use Your Information:
            </Typography>

            <Typography color="inherit" paragraph>
            2.1 Provide and Improve Our Services: We may use your personal information to process your orders, fulfill your requests, and deliver the products you purchase. Additionally, we use your information to improve our website, products, and services based on your feedback and browsing patterns.
            </Typography>

            <Typography color="inherit" paragraph>
            2.2 Communication: We may use your email address to send you transactional messages, order confirmations, shipping updates, and customer support responses. With your consent, we may also send you promotional emails about our latest products, offers, and news. You have the option to unsubscribe from these communications at any time.
            </Typography>

            <Typography color="inherit" paragraph>
            2.3 Analytics and Third-Party Services: We use third-party analytics tools, such as Google Analytics, to gather information about how you interact with our website. These tools may collect and analyze data such as your IP address, device information, and browsing behavior. This helps us understand user preferences and improve our website's functionality. Please refer to the respective privacy policies of these third-party services for more information on their data practices.
            </Typography>

            <Typography color="inherit" paragraph>
            2.4 Payment Processing: For secure payment transactions, we utilize a third-party payment processor, Stripe. When you make a purchase, your payment information is encrypted and transmitted directly to Stripe for payment processing. We do not store your payment details on our servers. Please review Stripe's privacy policy to understand how they handle your information.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
              3. Data Sharing and Disclosure:
            </Typography>

            <Typography color="inherit" paragraph>
            We respect the privacy of your personal information and do not sell, trade, or rent it to third parties for marketing purposes. However, we may share your information with trusted service providers who assist us in operating our website and delivering our services. These providers are contractually bound to handle your data securely and only use it for the purposes specified by us.
            </Typography>

            <Typography color="inherit" paragraph>
            We may also disclose your information if required by law, in response to a legal process, or to protect our rights, property, or safety, as well as those of our users or the public.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            4. Data Security:
            </Typography>

            <Typography color="inherit" paragraph>
            We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure. We strive to ensure the security of your data but cannot guarantee its absolute security.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            5. Children's Privacy:
            </Typography>

            <Typography color="inherit" paragraph>
            Our website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take immediate steps to remove that information from our records.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            6. Changes to this Privacy Policy:
            </Typography>

            <Typography color="inherit" paragraph>
            We reserve the right to modify or update this policy.
            </Typography>

          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Privacy;
