//importing component
import BookShelf from '../../components/BookShelf';
import {useEffect} from "react";

const BookShelfPage = () => {
  useEffect(() => {
    document.title = 'Bookshelf';
  }, []);
  return (
    <div>
      <BookShelf />
    </div>
  );
};

export default BookShelfPage;
