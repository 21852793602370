//importing components
import SignIn from '../../components/SignIn';
import {useEffect} from "react";
//login page
const Login: React.FC = () => {
  useEffect(() => {
    document.title = 'Login';
  }, []);
  return (
    <SignIn />
  );
};

export default Login;
