//importing hooks
import { useTypedSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
//importing types & selectors
import { selectCartItems, selectCartTotal } from '../../state';
import { createStructuredSelector } from 'reselect';
//importing utils
import { showTwoNumbersAfterDecimal } from '../../Utils';
import { v4 as uuidv4 } from 'uuid';
//importing components
import CheckoutItem from './item';

import { logWebEvent } from '../../firebase/utils';

import { functions } from '../../firebase/utils';
import { loadStripe } from '@stripe/stripe-js';

import {
  Button,
  Stack,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material"

const Checkout = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [stripeError, setStripeError] = useState('');


  const { cartItems, total } = useTypedSelector(
    createStructuredSelector({
      cartItems: selectCartItems,
      total: selectCartTotal,
    })
  );
  const { currentUser } = useTypedSelector(state => state.user);

  async function handleCheckout() {
    setOpen(true);
    setShowLoader(true);
    setStripeError('');
    const stripe = await loadStripe(process.env.REACT_APP_PUBLISHABLE_API_KEY!);
    if(!stripe){
      setShowLoader(false);
      setStripeError('Something went wrong. Please try again later.');
      return
    }
    const lineItems = [];
    const eventItems = [];
    for(const item of cartItems){
      lineItems.push({
        price: item.priceId ? item.priceId : "",
        quantity: 1
      });
      eventItems.push({
        "item_id": item.documentId,
        "item_name": item.name,
        "price": item.price,
        "quantity": 1
      })
    }
    const result = await functions.httpsCallable("createStripeSession")({lineItems: lineItems, value: total})
    .catch((error: any) => {
      console.warn(error.message);
    });
    if(!result){
      setShowLoader(false);
      setStripeError('Something went wrong. Please try again later.');
      return 
    }
    const sessionId = result.data;
    stripe.redirectToCheckout({
      sessionId: sessionId,
    }).then(function (result) {
      setShowLoader(false);
      setStripeError('Something went wrong. Please try again later.');
      console.log(result.error.message);
    });
    logWebEvent("begin_checkout", {"value": total, "currency": "USD", "items": eventItems})
  }
  
  return (
    <div className="checkout">
      <Stack spacing={2} mt="1rem">
        <Typography variant="h3">
          Your Cart
        </Typography>
      {cartItems.length > 0 ? (
        <Stack spacing={1} mt="1rem">
            {cartItems.map(product => {
              return <CheckoutItem product={product} key={uuidv4()} />;
            })}
            <Card raised>
              <CardContent>
                <Typography variant="h5" sx={{fontWeight: 700}}>
                Total: ${showTwoNumbersAfterDecimal(total)}
                </Typography>
                <Grid container justifyContent={"space-evenly"} rowSpacing={2} sx={{mt: 1}}>
                  <Grid item xs={12} sm={5}><Button onClick={() => history.push('/search')} color="secondary" variant="contained" sx={{fontWeight: 700, width: '100%'}}>Continue Shopping</Button></Grid>
                  <Grid item xs={12} sm={5}><Button onClick={()=>{currentUser ? handleCheckout() : history.push('/login')}}  color="secondary" variant="contained" sx={{fontWeight: 700, width: '100%'}}>{currentUser ? 'Checkout' : 'Login To Continue'}</Button></Grid>
                </Grid>
              </CardContent>
            </Card>
        </Stack>
      ) : (
        <Typography>You have no items in your cart.</Typography>
      )}
      </Stack>
      <Dialog open={open} onClose={()=>{setOpen(false)}}  fullWidth={true}>
        <DialogTitle sx={{fontWeight: "700"}}>Contacting Stripe...</DialogTitle>
        <DialogContent>
          {showLoader && <Box sx={{ display: 'flex' }} justifyContent={"center"}>
            <CircularProgress color="secondary"/>
          </Box>}
          {stripeError && stripeError}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Checkout;
