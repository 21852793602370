//importing components
import SignUp from '../../components/SignUp';
import {useEffect} from "react";
//registration page
const Registration: React.FC = () => {
  useEffect(() => {
    document.title = 'Register';
  }, []);
  return (
    <div>
      <SignUp />
    </div>
  );
};

export default Registration;
