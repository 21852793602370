//types
export enum ActionType {
  //START
  ADD_NEW_PRODUCT_START = 'ADD_NEW_PRODUCT_START',
  FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START',
  FETCH_BOOKSHELF_START = 'FETCH_BOOKSHELF_START',
  FETCH_PRODUCT_START = 'FETCH_PRODUCT_START',
  DELETE_PRODUCT_START = 'DELETE_PRODUCT_START',
  //OTHER
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCT = 'SET_PRODUCT',
  SET_BOOKSHELF = 'SET_BOOKSHELF',
}
