//importing hooks & router utils
import { useEffect, useState } from 'react';
import { useTypedSelector, useUserActions } from '../../hooks';
import { Link } from 'react-router-dom';
//importing router utils
import { useHistory } from 'react-router-dom';
import {
  CardContent,
  Card,
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { Google } from 'mdi-material-ui'
//sign in component
const SignIn: React.FC = () => {
  //local state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  //redux actions, router history & state
  const history = useHistory();
  const {
    emailSignInStart,
    googleSignInStart,
    resetUserState,
  } = useUserActions();
  const { currentUser, userErrors } = useTypedSelector(state => state.user);
  //reset form
  const resetForm = () => {
    setEmail('');
    setPassword('');
    setErrors([]);
  };
  //resetting user on first render
  useEffect(() => {
    resetUserState();
  }, [resetUserState]);
  //resetting forms & redirecting
  useEffect(() => {
    if (currentUser) {
      resetForm();
      history.goBack();
    } else {
      setErrors(userErrors);
    }
  }, [currentUser, history, userErrors]);
  //on submit handler
  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailSignInStart({ email, password });
  };
  return (
    <Grid container alignItems={'flex-start'} justifyContent={"center"} sx={{height: "100vh", mt: 4}}>
      <Grid item sx={{maxWidth: "500px"}} xs={12} sm={6}>
        <Card raised  >
          <CardContent >
            <Stack spacing={2} sx={{}}>
              <Typography sx={{width: "100%"}} variant="h4" textAlign={'center'}>
                Log In
              </Typography>
              {errors.length > 0 && (
                <ul>
                  {errors.map((err, index) => {
                    return (
                      <li style={{ lineHeight: '1.5', margin: '0 10px' }} key={index}>
                        {err}
                      </li>
                    );
                  })}
                </ul>
              )}
                <form onSubmit={onSubmitHandler}>
                  <Stack spacing={1} sx={{width: "100%"}}>
                    <TextField
                      sx={{width: "100%"}}
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                    />
                    <TextField
                      sx={{width: "100%"}}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                    />
                    <Link className="password__recovery" to="/recovery">
                      Forgot Password?
                    </Link>
                    <Button variant="contained" color="secondary" type="submit">Sign In</Button>
                  </Stack>
                </form>
              <Typography sx={{width: "100%"}} variant="h6" textAlign={'center'}>
                - Or -
              </Typography>
              <Button startIcon={<Google />} onClick={() => googleSignInStart()} variant="contained" sx={{backgroundColor: "black", color: "white"}}>
                Sign In With Google
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default SignIn;
