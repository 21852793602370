import {
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import {useEffect} from "react";

//Privacy page
const Terms: React.FC = () => {
  useEffect(() => {
    document.title = 'Terms Of Service';
  }, []);
  return (
    <Paper sx={{mt:3}}>
      <Grid container sx={{padding: 3}} justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <div>
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
            Terms of Service
            </Typography>
            <Typography color="inherit" paragraph>
            Effective Date: 01/01/2023
            </Typography>

            <Typography color="inherit" paragraph>
            Please read these Terms of Service ("Terms") carefully before using the Colorful Expression website ("Website") or engaging with our services. These Terms govern your access to and use of our Website, products, and services. By accessing or using our Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using our Website.
            </Typography>

            <Typography color="inherit" paragraph  mt={3}>
              1. Use of Website and Services:
            </Typography>

            <Typography color="inherit" paragraph>
            1.1 You must be at least 18 years old or have the necessary legal capacity to enter into a binding agreement in your jurisdiction to use our Website and services. By using our Website, you represent and warrant that you meet the eligibility requirements.
            </Typography>

            <Typography color="inherit" paragraph>
            1.2 Account Creation: To access certain features or services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account. You agree to provide accurate and up-to-date information during the registration process and to notify us promptly of any changes.
            </Typography>

            <Typography color="inherit" paragraph>
            1.3Prohibited Conduct: You agree not to use our Website for any unlawful or unauthorized purposes. This includes but is not limited to:
            - Violating any applicable laws, regulations, or third-party rights.
            - Impersonating another person or entity.
            - Engaging in any form of harassment, hate speech, or offensive behavior.
            - Uploading or transmitting any viruses, malware, or other harmful content.
            - Interfering with the proper functioning of the Website or attempting to gain unauthorized access to our systems.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            2. Intellectual Property:
            </Typography>

            <Typography color="inherit" paragraph>
            2.1 Ownership:
            All content, trademarks, logos, designs, and intellectual property rights on our Website are owned by Colorful Expression or its licensors. You acknowledge that these rights are protected by copyright, trademark, and other laws, and you agree not to infringe upon them.
            </Typography>

            <Typography color="inherit" paragraph>
            2.2 Limited License:
            Subject to your compliance with these Terms, Colorful Expression grants you a limited, non-exclusive, non-transferable license to access and use the Website and its content for personal, non-commercial purposes. This license does not permit the resale, distribution, or modification of any content without our prior written consent.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            3. Product Information and Pricing:
            </Typography>

            <Typography color="inherit" paragraph>
            3.1 Product Descriptions:
            We strive to provide accurate and detailed information about our products. However, we do not warrant that the product descriptions, colors, or other content on our Website are error-free, complete, or current. We reserve the right to correct any errors, inaccuracies, or omissions and to update product information at any time without prior notice.
            </Typography>

            <Typography color="inherit" paragraph>
            3.2 Pricing and Payment:
            All prices displayed on our Website are in the applicable currency and do not include taxes or shipping fees unless otherwise stated. We reserve the right to change product prices at any time without notice. Payment for purchases made on our Website is processed through secure third-party payment processors, and you agree to abide by their terms and conditions.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            4. Limitation of Liability:
            </Typography>

            <Typography color="inherit" paragraph>
            To the fullest extent permitted by applicable law, Colorful Expression and its affiliates, officers, employees, agents, and suppliers shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Website or services, including but not limited to any errors or omissions in content, any unauthorized access to or use of our servers, or any interruption or cessation of services.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            5. Indemnification:
            </Typography>

            <Typography color="inherit" paragraph>
            You agree to indemnify, defend, and hold Colorful Expression and its affiliates, officers, employees, agents, and suppliers harmless from any claims, liabilities, damages, losses, costs, or expenses, including reasonable attorneys' fees, arising from your use of the Website, violation of these Terms, or infringement of any intellectual property or other rights of any third party.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            6. Governing Law and Dispute Resolution:
            </Typography>

            <Typography color="inherit" paragraph>
            These Terms shall be governed by and construed in accordance with the laws of the state of California, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these Terms or your use of the Website shall be subject to the exclusive jurisdiction of the state and federal courts located in California.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            7. Modifications to the Terms:
            </Typography>

            <Typography color="inherit" paragraph>
            We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after the posting of modifications constitutes your acceptance of the revised Terms.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            8. Severability:
            </Typography>

            <Typography color="inherit" paragraph>
            If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
            </Typography>

            <Typography color="inherit" paragraph mt={3}>
            9. Entire Agreement:
            </Typography>

            <Typography color="inherit" paragraph>
            These Terms constitute the entire agreement between you and Colorful Expression regarding your use of the Website and supersede any prior agreements or understandings, whether oral or written.
            </Typography>

            <Typography color="inherit" paragraph>
            If you have any questions or concerns regarding these Terms, please contact us at support@colorfulexpression.com.
            </Typography>

            <Typography color="inherit" paragraph>
            Thank you for reviewing our Terms of Service. Enjoy your experience at Colorful Expression!
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Terms;
