//importing hooks
import { useTypedSelector, useUserActions } from '../../hooks';
import { selectCartItemsCount } from '../../state';
import { useState } from 'react';
//importing utils
import { useHistory } from 'react-router-dom';
//importing font awesome icons
import { Cart } from 'mdi-material-ui';
import {
  Box,
  Container,
  IconButton,
  Menu,
  Button,
  AppBar,
  Typography,
  MenuItem,
  Tooltip,
  Avatar,
  Stack,
  Divider,
} from "@mui/material"
import MenuIcon from "@material-ui/icons/Menu";


const Header: React.FC = () => {
  const history = useHistory();
  const { currentUser } = useTypedSelector(state => state.user);

  const totalCartItems = useTypedSelector(state => selectCartItemsCount(state));

  const { emailSignOutStart } = useUserActions();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorElUser(event.currentTarget);
  };

  const handleUserNav = (loc: string) => {
    setAnchorElUser(null);
    history.push(loc);
  }

  const handleNav = (loc: string) => {
    setAnchorElNav(null);
    history.push(loc);
  }
  return (
      <AppBar position="static" color="primary">
        <Container maxWidth="xl">
          <Stack direction="row"
              justifyContent="space-between"
              alignItems="center">
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Colorful Expression
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {Boolean(anchorElNav) && <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={()=>{setAnchorElNav(null)}}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem key='Shop' onClick={()=>handleNav('/search')}>
                  <Typography textAlign="center" color="black">Shop</Typography>
                </MenuItem>
                <MenuItem key='Bookshelf' onClick={()=>handleNav('/bookshelf')}>
                  <Typography textAlign="center" color="black">Bookshelf</Typography>
                </MenuItem>
                <MenuItem key='cart' onClick={()=>handleNav('/cart')}>
                  <Typography textAlign="center" color="black">Cart ({totalCartItems})</Typography>
                </MenuItem>
                {!currentUser && (
                  <MenuItem key='login' onClick={()=>handleNav('/login')}>
                    <Typography textAlign="center" color="black">Login</Typography>
                  </MenuItem>
                )}
                {!currentUser && (
                  <MenuItem key='register' onClick={()=>handleNav('/registration')}>
                    <Typography textAlign="center" color="black">Register</Typography>
                  </MenuItem>
                )}
              </Menu>}
            </Box>
            <Typography
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Colorful Expression
            </Typography>
            <Stack direction="row" sx={{ display: { xs: 'none', md: 'flex' }}} divider={<Divider orientation="vertical" flexItem variant="middle" light sx={{mt: '15px', mb: '15px'}} />}>
              <Button
                  key='shop'
                  onClick={()=>handleNav('/search')}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >Shop</Button>
                <Button
                  key='Bookshelf'
                  onClick={()=>handleNav('/bookshelf')}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >Bookshelf</Button>
            </Stack>

            <Stack direction="row" alignItems="center" divider={!currentUser && <Divider orientation="vertical" flexItem variant="middle" light sx={{mt: '15px', mb: '15px'}} />}>
              <Button
                  key='cart'
                  onClick={()=>handleNav('/cart')}
                  sx={{ my: 2, color: 'black' }}
                  startIcon={<Cart/>}
                >
                ({totalCartItems})</Button>
                {!currentUser && (
                  <Button
                    key='Login'
                    onClick={()=>handleNav('/login')}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                  >Login</Button>
                )}
                {!currentUser && (
                  <Button
                    key='signup'
                    onClick={()=>handleNav('/registration')}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                  >Register</Button>
                )}

            {currentUser && <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={currentUser ? currentUser.displayName : 'You'} />
                </IconButton>
              </Tooltip>
              {Boolean(anchorElUser) && <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={()=>{setAnchorElUser(null)}}
              >
                  <MenuItem key='account' onClick={()=>handleUserNav('/account')}>
                    <Typography textAlign="center">Account</Typography>
                  </MenuItem>
                  <MenuItem key='support' onClick={()=>handleUserNav('/support')}>
                    <Typography textAlign="center">Support</Typography>
                  </MenuItem>
                  <MenuItem key='logout' onClick={() => {setAnchorElUser(null); emailSignOutStart()}}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
              </Menu>}
            </Box>}
            </Stack>
          </Stack>
        </Container>
      </AppBar>
  );
};

export default Header;
