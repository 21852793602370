import { Stack, Typography, Link, IconButton } from "@mui/material";
import { Instagram } from "mdi-material-ui";
import { Facebook } from "mdi-material-ui";

const Footer: React.FC = () => {
  return <footer>
    <Stack spacing={2}>
    <Stack direction="row" justifyContent={"center"} spacing={2} sx={{zIndex: "100"}}>
      <IconButton href="https://www.instagram.com/acolorfulexpression/"><Instagram sx={{ fontSize: '1.875rem'}} color="secondary"/></IconButton>
      <IconButton href="https://www.facebook.com/acolorfulexpression"><Facebook  sx={{ fontSize: '1.875rem'}} color="secondary"/></IconButton>
    </Stack>
    <Stack direction="row" justifyContent={"center"} spacing={2}>
      <Link href="/about" color="inherit">About</Link>
      <Link href="/support" color="inherit">Support</Link>
      <Link href="/terms" color="inherit">Terms</Link>
      <Link href="/privacy" color="inherit">Privacy</Link>
    </Stack>
    <Typography>
      &copy; Copyright 2023, Colorful Expression
    </Typography>
    </Stack>
  </footer>;
};

export default Footer;
