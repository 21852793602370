import {
  Paper,
  Typography,
  Grid,
  Box
} from '@mui/material';
import {useEffect} from "react";

//Support page
const Support: React.FC = () => {
  useEffect(() => {
    document.title = 'Support';
  }, []);
  return (
    <Paper sx={{mt:3}}>
      <Grid container sx={{padding: 3}} justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <div>
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
              Support
            </Typography>
            <Typography color="inherit" paragraph>
              We're here to assist you every step of the way! At Colorful Expression, we value your satisfaction and strive to provide exceptional customer support. If you have any questions, concerns, or need assistance with your purchase, we're just an email away.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
            Fast and Efficient Support
            </Typography>
            <Typography color="inherit" paragraph>
              For the fastest and most efficient service, please reach out to us via email at <Box fontWeight='fontWeightBold' fontSize={"1.25em"} display='inline'>support@colorfulexpression.com</Box>. Our dedicated support team is ready to assist you with any inquiries you may have. Whether you need help choosing the perfect coloring book, have questions about our products, or require assistance with an order, we're here to help.
            </Typography>
            <Typography color="inherit" paragraph>
              With a vision in mind, our team embarked on a journey to design coloring books that would ignite the imagination and foster artistic expression. We put our heart and soul into each and every page, carefully curating intricate designs, captivating scenes, and engaging patterns. 
              Our goal is to provide you with a truly immersive coloring experience that allows you to escape the everyday hustle and tap into your inner artist.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
            Timely Responses
            </Typography>
            <Typography color="inherit" paragraph>
            We understand the importance of prompt responses when it comes to customer support. Our team is committed to addressing your queries as quickly as possible. You can expect a response from us within 24-48 hours during our regular business hours, Monday through Friday.
            </Typography>
            <Typography color="inherit" paragraph>
            We encourage you to provide as much detail as possible in your email so that we can assist you effectively. If you have encountered an issue with your order, kindly include your order number and any relevant information to expedite the resolution process.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
            Join Our Community
            </Typography>
            <Typography color="inherit" paragraph>
            In addition to our email support, we also invite you to join our vibrant community on social media. Connect with us on Facebook, Instagram, and Twitter to stay updated on the latest news, promotions, and coloring inspiration.
            Our social media channels are also a great place to interact with fellow coloring enthusiasts and share your beautiful creations.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
            We're Here for You
            </Typography>
            <Typography color="inherit" paragraph>
            At Colorful Expression, customer satisfaction is at the core of everything we do. We value your feedback and are constantly striving to improve our products and services. If you have any suggestions, ideas, or feedback, please don't hesitate to share them with us. Your input helps us make your coloring experience even more enjoyable.
            </Typography>

            <Typography color="inherit" paragraph>
            Thank you for choosing Colorful Expression. We appreciate your support and look forward to assisting you!
            </Typography>
            <Typography color="inherit" paragraph>
            The Colorful Expression Support Team
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Support;
