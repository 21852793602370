//importing hooks
import { useState, useEffect } from 'react';
import { useTypedSelector, useUserActions } from '../../hooks';
//importing router utils
import { useHistory } from 'react-router-dom';
import {
  CardContent,
  Card,
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Google } from 'mdi-material-ui'

//sign up component
const SignUp: React.FC = () => {
  //local state
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  //redux actions, router history & state
  const history = useHistory();
  const { emailSignUpStart, resetUserState, googleSignInStart } = useUserActions();
  const { currentUser, userErrors } = useTypedSelector(state => state.user);

  //reset form
  const resetForm = () => {
    setErrors([]);
    setEmail('');
    setDisplayName('');
    setConfirmPassword('');
    setPassword('');
  };
  //resetting user on first render
  useEffect(() => {
    resetUserState();
  }, [resetUserState]);
  //resetting form & redirecting
  useEffect(() => {
    if (currentUser) {
      resetForm();
      history.push('/bookshelf');
    } else {
      setErrors(userErrors);
    }
  }, [history, currentUser, userErrors]);
  //on submit handler
  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailSignUpStart({ displayName, email, password, confirmPassword });
  };
  return (
    <Grid container alignItems={'flex-start'} justifyContent={"center"} sx={{height: "100vh", mt: 4}}>
      <Grid item sx={{maxWidth: "500px"}} xs={12} sm={6}>
        <Card raised  >
          <CardContent >
            <Stack spacing={2} sx={{}}>
              <Typography sx={{width: "100%"}} variant="h4" textAlign={'center'}>
                Register
              </Typography>
              {errors.length > 0 && (
        <ul>
          {errors.map((err, index) => {
            return (
              <li style={{ lineHeight: '1.5', margin: '0 10px' }} key={index}>
                {err}
              </li>
            );
          })}
        </ul>
      )}
                <form onSubmit={onSubmitHandler}>
                  <Stack spacing={1} sx={{width: "100%"}}>
                    <TextField
                      sx={{width: "100%"}}
                      onChange={e => setDisplayName(e.target.value)}
                      type="text"
                      name="displayName"
                      placeholder="Full Name"
                      value={displayName}
                    />
                    <TextField
                      sx={{width: "100%"}}
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                    />
                    <TextField
                      sx={{width: "100%"}}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                    />
                    <TextField
                      sx={{width: "100%"}}
                      onChange={e => setConfirmPassword(e.target.value)}
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      value={confirmPassword}
                    />
                    <Button variant="contained" color="secondary" type="submit">Register</Button>
                    <Typography sx={{width: "100%"}} variant="h6" textAlign={'center'}>
                      - Or -
                    </Typography>
                    <Button startIcon={<Google />} onClick={() => googleSignInStart()} variant="contained" sx={{backgroundColor: "black", color: "white"}}>
                      Register With Google
                    </Button>
                  </Stack>
                </form>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignUp;
