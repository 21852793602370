//importing components
import {
  Button,
} from "@mui/material"
interface LoadMoreProps {
  onLoadMore: () => void;
}

const LoadMore: React.FC<LoadMoreProps> = ({ onLoadMore = () => {} }) => {
  return <Button variant="contained" color="secondary" onClick={() => onLoadMore()} sx={{mt:"30px", minWidth: "50%"}} >Load more</Button>;
};

export default LoadMore;
