import { useHistory } from 'react-router-dom';
//importing types
import { ProductData } from '../../../interfaces';
//importing components
import {
  Button,
  Typography,
  CardActions,
  CardContent,
  CardMedia,
  CardActionArea,
  Card,
  Stack,
} from "@mui/material"
//props interface
interface ProductProps {
  product: ProductData;
}
//product component
const Product: React.FC<ProductProps> = ({ product }) => {
  const history = useHistory();
  //destructuring
  const { name, price, thumbnail, documentId } = product;
  //type guards
  if (!documentId) return null;
  return (
    <Card raised onClick={()=>{history.push(`/product/${documentId}`)}}>
      <CardActionArea >
        <CardMedia 
          onClick={()=>{history.push(`/product/${documentId}`)}}
          component="img"
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain", maxHeight: '300px'}}
          image={`https://ik.imagekit.io/cdycj9lbl/tr:h-400,w-300${thumbnail}`}
          alt={name}
        />
        <CardContent sx={{padding: '10px'}}>
          <Typography variant="h6" component="div" align="center" sx={{fontWeight: 700}}>
            {name}
          </Typography>
          <Typography variant="h6" component="div" align="center">
            ${price}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{padding: '15px'}}>
        <Stack direction="row" justifyContent="space-around" sx={{width: '100%'}}>
          <Button variant="contained" size="small" color="secondary" onClick={()=>{history.push(`/product/${documentId}`)}}>
            Details
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default Product;
