//importing firebase utils
import { db } from '../../firebase/utils';
//importing types
import { Order, ProductDataLite } from '../../interfaces';
//helpers

export const handleSaveOrder = (order: Order) => {
  return new Promise((resolve, reject) => {
    db.collection('orders')
      .doc()
      .set(order)
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};


export const handleGetOrderHistory = (uid: string) => {
  return new Promise((resolve, reject) => {
    //ordering orders
    db.collection('users')
      .doc(uid).collection('payments')
      .get()
      .then(snapshot => {
        const data = [
          ...snapshot.docs.map(doc => {
            return {
              orderCreatedDate: doc.data().created,
              orderUserId: uid,
              orderTotal: doc.data().charges.data[0].amount / 100,
              orderItems: [],
              documentId: doc.id,
            };
          }),
        ];
        resolve(data);
      })
      .catch(err => reject(err));
  });
};
export const handleGetOrder = (orderId: string) => {
  return new Promise((resolve, reject) => {
    const [uid, order] = orderId.split(':::');
     db.collection('users')
      .doc(uid)
      .collection('payments')
      .doc(order)
      .get()
      .then(snapshot => {
        const items: ProductDataLite[] = [];
        for(const item of snapshot.data()?.items){
          items.push({
            name: item.description,
            thumbnail: '',
            documentId: item.price.product,
            price: item.price.unit_amount / 100,
            quantity: item.quantity
          })
        }
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            orderCreatedDate: snapshot.data()?.created,
            orderUserId: uid,
            orderTotal: snapshot.data()?.charges.data[0].amount / 100,
            orderItems: items,
            documentId: order,
          });
        }
      })
      .catch(err => reject(err));
  });
};
