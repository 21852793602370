//importing hooks
import { useState, useEffect } from 'react';
import { useUserActions, useTypedSelector } from '../../hooks';
//import router utils
import { useHistory } from 'react-router-dom';
//importing components
import {
  CardContent,
  Card,
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
} from "@mui/material"

const PasswordRecovery: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<string[]>([]);

  const history = useHistory();

  const { recoverPasswordStart, resetUserState } = useUserActions();
  const { recoverPasswordSuccess, userErrors } = useTypedSelector(
    state => state.user
  );

  useEffect(() => {
    resetUserState();
  }, [resetUserState]);

  useEffect(() => {
    if (recoverPasswordSuccess) {
      setEmail('');
      resetUserState();
      history.push('/login');
    } else {
      setErrors(userErrors);
    }
  }, [recoverPasswordSuccess, history, userErrors, resetUserState]);

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email.length > 0) {
      recoverPasswordStart(email);
      setErrors([]);
    } else {
      setErrors(['Please enter a valid email.']);
    }
  };

  return (

    <Grid container alignItems={'flex-start'} justifyContent={"center"} sx={{height: "100vh", mt: 4}}>
    <Grid item sx={{maxWidth: "500px"}} xs={12} sm={6}>
      <Card raised  >
        <CardContent >
          <Stack spacing={2} sx={{}}>
            <Typography sx={{width: "100%"}} variant="h4" textAlign={'center'}>
              Password Recovery
            </Typography>
            {errors.length > 0 && (
              <ul>
                {errors.map((err, index) => {
                  return (
                    <li style={{ lineHeight: '1.5', margin: '0 10px' }} key={index}>
                      {err}
                    </li>
                  );
                })}
              </ul>
            )}
              <form onSubmit={onSubmitHandler}>
                <Stack spacing={1} sx={{width: "100%"}}>
                  <TextField
                    sx={{width: "100%"}}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                  />
                  <Button variant="contained" color="secondary" type="submit">Send me instructions</Button>
                </Stack>
              </form>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  );
};

export default PasswordRecovery;
