import {
  Paper,
  Typography,
  Grid
} from '@mui/material';
import {useEffect} from "react";

//About page
const About: React.FC = () => {
  useEffect(() => {
    document.title = 'About';
  }, []);
  return (
    <Paper sx={{mt:3}}>
      <Grid container sx={{padding: 3}} justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <div>
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
              About Colorful Expression
            </Typography>
            <Typography color="inherit" paragraph>
              At Colorful Expression, we believe in the power of art to inspire, relax, and bring joy to people's lives. We are a small team of passionate individuals based in the vibrant state of California. 
              Since our establishment in 2022, we have been dedicated to creating high-quality coloring books that capture the essence of imagination and creativity.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
              Our Journey
            </Typography>
            <Typography color="inherit" paragraph>
              The idea behind Colorful Expression was born out of a shared love for art and a desire to create a platform where individuals of all ages could immerse themselves in a world of colors.
              We recognized the therapeutic benefits that coloring offers, not only to children but also to adults seeking a form of relaxation and stress relief in their busy lives.
            </Typography>
            <Typography color="inherit" paragraph>
              With a vision in mind, our team embarked on a journey to design coloring books that would ignite the imagination and foster artistic expression. We put our heart and soul into each and every page, carefully curating intricate designs, captivating scenes, and engaging patterns. 
              Our goal is to provide you with a truly immersive coloring experience that allows you to escape the everyday hustle and tap into your inner artist.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
              What Sets Us Apart
            </Typography>
            <Typography color="inherit" paragraph>
              At Colorful Expression, we pride ourselves on creating coloring books that cater to the needs of both adults and children. While our designs offer intricate details and complex patterns for adult coloring enthusiasts,
              we also offer a range of books designed specifically for children, featuring adorable characters and playful illustrations.
            </Typography>
            <Typography color="inherit" paragraph>
              We offer each and every title in our inventory as a purchasable digital download, so you can print your favorite pages at home as many times as you like! Each physical product comes with a code you can redeem for a free digital copy of the title.
            </Typography>
            <Typography color="inherit" paragraph>
              Quality is of utmost importance to us. We understand that coloring is not just a hobby, but a form of self-expression and relaxation.
              That's why we meticulously select premium paper to ensure that your coloring experience is nothing short of exceptional. Our books are designed to be durable, allowing you to use a variety of coloring mediums such as colored pencils, markers, and gel pens without worrying about bleed-through.
            </Typography>

            <Typography component="h2" variant="h5" color="inherit" gutterBottom>
              Join the Colorful Expression Community
            </Typography>
            <Typography color="inherit" paragraph>
              We believe that art should be accessible to everyone, and that's why we strive to offer our coloring books at an affordable price point. We want to spread the joy of coloring far and wide, encouraging individuals of all ages to embrace their creativity and find solace in the meditative act of coloring.
            </Typography>
            <Typography color="inherit" paragraph>
              By choosing Colorful Expression, you're not just purchasing a coloring book; you're joining a vibrant community of like-minded individuals who appreciate the power of art.
              Connect with us through our social media channels, where we share coloring tips, showcase our fans' creations, and keep you updated on the latest releases and promotions.
            </Typography>
            <Typography color="inherit" paragraph>
              Thank you for choosing Colorful Expression as your coloring companion. We are honored to be a part of your artistic journey and look forward to helping you create a world of vibrant imagination, one stroke at a time.
            </Typography>

            <Typography color="inherit" paragraph>
              Happy coloring!
            </Typography>
            <Typography color="inherit" paragraph>
              The Colorful Expression Team
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default About;
